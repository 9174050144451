body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap');

input {
  caret-color: rgb(0, 0, 0);
}

body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  place-items: center;
  overflow: hidden;
  font-family: poppins;
}

.container {
  position: relative;
  width: 350px;
  height: 550px;
  /*border-radius: 20px;*/
  padding: 40px;
  box-sizing: border-box;
  /*background: rgb(255, 255, 255);*/
  /*box-shadow: 15px 15px 20px #cbced1, -15px -15px 20px white;*/
}

.brand-logo {
  height: 95px;
  width: 95px;
  content: url("/public/logo_int_egra.png");
  margin: auto;
  border-radius: 5%;
  box-sizing: border-box;
}

.brand-title {
  margin-top: 35px;
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 1.3rem;
  color: #0f1011;
  letter-spacing: 1px;
}

.Footer1 {

  width: 33%;
  float:left;
  margin-top: 30px;
  font-size: 11px;
}

.Footer2 {

  width: 33%;
  float:left;
  margin-top: 30px;
  font-size: 11px;
}

.Footer3 {

  width: 33%;
  float:left;
  margin-top: 30px;
  font-size: 11px;
}

.FooterIconsWhatsApp{
  height: 60px;
  width: 60px;
  content: url("/public/icon_whatsapp.png");
  margin:auto;
}

.FooterIconsAPPs{
  height: 60px;
  width: 60px;
  content: url("/public/icon_download.png");
  margin:auto;
}

.FooterIconsHelp{
  height: 60px;
  width: 60px;
  content: url("/public/icon_problem.png");
  margin:auto;
}

.custom-select{

  margin-bottom: 20px;
  background-color: #ffffff;
  border-color: lightgray;
  border-radius: 10%;
  font-size: 15px;
  font-family: poppins;

}

.ClickHere{

  color: rgb(1, 138, 173);
  text-decoration: underline;

}

.inputs {
  text-align: left;
  margin-top: 30px;
}

label, button {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
  margin-top: 14px;
}

label {
  /*margin-top: 16px;
  margin-bottom: 6px;*/
}

label:nth-of-type(2) {
  /*margin-top: 24px;*/
}

input::placeholder {
  color: gray;
}

input {
  background: #ecf0f3;
  padding: 10px;
  padding-left: 20px;
  height: 50px;
  font-size: 14px;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

button {
  color: white;
  margin-top: 40px;
  background: #1DA1F2;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 900;
  box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white;
  transition: 0.5s;
}

button:hover {
  box-shadow: none;
}

a {
  font-size: 11px;
  bottom: 4px;
  right: 4px;
  text-decoration: none;
  border-radius: 10px;
  padding: 2px;
  color: black;
}

h1 {
  position: absolute;
  top: 0;
  left: 0;
}
